import React, { useContext } from 'react'
import { TabContext } from './Tabs'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './TabPanel.module.css'

const TabPanel = ({ identifier, className = '', children }) => {
  const tabPanelClassNames = classNames([className, 'tab-panel'])
  const tabContext = useContext(TabContext)

  return (
    tabContext.activeTab === identifier && (
      <div className={tabPanelClassNames}>{children}</div>
    )
  )
}

TabPanel.propTypes = {
  className: PropTypes.string,
  identifier: PropTypes.string,
  children: PropTypes.node,
}

export default TabPanel
