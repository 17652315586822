import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './TabList.module.css'

const TabList = ({ className = '', children }) => {
  const tabListClassNames = classNames([styles.tabList, className])

  return <div className={tabListClassNames}>{children}</div>
}

TabList.propTypes = {
  className: PropTypes.string,
  initialValue: PropTypes.string,
  children: PropTypes.node,
}

export default TabList
