import React, { useContext } from 'react'
import { TabContext } from './Tabs'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Tab.module.css'

const Tab = ({
  identifier,
  className = '',
  kind = 'base',
  onClick = () => {},
  children,
}) => {
  const tabClassNames = classNames([styles.tab, className], {
    [styles.isButton]: kind === 'button',
    [styles.link]: kind === 'link',
  })
  const tabContext = useContext(TabContext)

  const handleClick = event => {
    tabContext.changeTab(identifier)
    onClick(event)
  }

  return (
    <span
      className={`
      ${tabClassNames}
      ${tabContext.activeTab === identifier ? styles.active : ''}
      `}
      onClick={handleClick}
    >
      {children}
    </span>
  )
}

Tab.propTypes = {
  className: PropTypes.string,
  identifier: PropTypes.string,
  onClick: PropTypes.func,
  kind: PropTypes.oneOf(['base', 'link', 'button']),
  children: PropTypes.node,
}

export default Tab
