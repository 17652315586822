import React from 'react'
import PropTypes from 'prop-types'
import {
  TemplateBreadCrumbs,
  Typography,
  Divider,
  PressOfficeWidget,
  Social,
  PinBlockGroup,
  IonoPodcast,
  Tabs,
} from '../../components'
import styles from './OurResourcesTemplate.module.css'
import { graphql } from 'gatsby'
import ContentRightSidebar from '../../components/Layout/ContentRightSidebar/ContentRightSidebar'
import { flattenNodes } from '../../utils'
import { head } from 'ramda'
import TabList from '../../components/elements/Tabs/TabList'
import Tab from '../../components/elements/Tabs/Tab'
import TabPanel from '../../components/elements/Tabs/TabPanel'

const OurResourcesTemplate = ({ data }) => {
  const {
    aboutPage: {
      frontmatter: { header },
    },
    // categories,
  } = data

  const breadcrumbs = [
    { label: 'About Us', url: '/about-us/' },
    { label: 'Our Resources' },
  ]

  const podcasts = flattenNodes(data.podcasts.edges)
  const cats = flattenNodes(data.categories.edges)
  const documents = flattenNodes(data.documents.edges)
  const documentCats = flattenNodes(data.documentCategories.edges)

  return (
    <>
      <ContentRightSidebar header={header}>
        <div>
          <TemplateBreadCrumbs breadcrumbs={breadcrumbs} />
          <Divider thickness={4} />
          <Typography tag="h1" type="boxHeading" className="mb-8">
            Our Resources
          </Typography>

          <Typography tag="h2" className="mb-4">
            Documents
          </Typography>

          <Tabs initialValue={head(documentCats).title} className="mb-8">
            <div className={styles.tabsHeader}>
              <TabList className={styles.tabList}>
                {documentCats.map(cat => {
                  return (
                    cat && (
                      <Tab key={cat.title} identifier={cat.title} kind="link">
                        {cat.title}
                      </Tab>
                    )
                  )
                })}
              </TabList>
            </div>

            {documentCats.map(cat => {
              return (
                <TabPanel key={cat.title} identifier={cat.title}>
                  <div key={cat.title}>
                    <PinBlockGroup key={1} columns={1} />
                    <ul className="list-disc pl-2 ml-8">
                      {documents
                        .filter(document =>
                          cat.title.includes(document.documentCategories),
                        )
                        .map((document, index) => {
                          return (
                            <li key={`document-${index}`}>
                              <a
                                href={document.file}
                                target="__blank"
                                className="text-blue hover:underline"
                              >
                                {document.title}
                              </a>
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                </TabPanel>
              )
            })}
          </Tabs>

          <Typography tag="h2" className="mb-4">
            Podcasts
          </Typography>

          <Tabs initialValue={head(cats).title}>
            <div className={styles.tabsHeader}>
              <TabList className={styles.tabList}>
                {cats.map(cat => {
                  return (
                    cat && (
                      <Tab key={cat.title} identifier={cat.title} kind="link">
                        {cat.title}
                      </Tab>
                    )
                  )
                })}
              </TabList>
            </div>

            {cats.map(cat => {
              return (
                <TabPanel key={cat.title} identifier={cat.title}>
                  <div key={cat.title}>
                    <PinBlockGroup key={1} columns={1} />
                    {podcasts
                      .filter(podcast =>
                        cat.title.includes(podcast.podcastCategories),
                      )
                      .map(podcast => {
                        return (
                          <>
                            <IonoPodcast key={1} url={podcast.url} />
                          </>
                        )
                      })}
                  </div>
                </TabPanel>
              )
            })}
          </Tabs>
        </div>
        <div>
          <Social orientation="horizontal" />
          <Divider thickness={4} />
          <PressOfficeWidget />
        </div>
      </ContentRightSidebar>
    </>
  )
}

OurResourcesTemplate.propTypes = {
  data: PropTypes.shape({
    aboutPage: PropTypes.any,
    categories: PropTypes.any,
    podcasts: PropTypes.shape({
      edges: PropTypes.array,
    }),
    documentCategories: PropTypes.shape({
      edges: PropTypes.array,
    }),
    documents: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default OurResourcesTemplate

export const pageQuery = graphql`
  query OurResourcesPageQuery {
    aboutPage: mdx(fields: { slug: { eq: "/about-us/" } }) {
      frontmatter {
        ...headerFields
      }
    }
    categories: allMdx(
      filter: { fields: { contentType: { eq: "podcastCategories" } } }
      sort: { fields: frontmatter___order, order: ASC }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
          }
        }
      }
    }
    podcasts: allMdx(
      filter: { fields: { contentType: { eq: "podcasts" } } }
      sort: { fields: frontmatter___publishedDate, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            url
            podcastCategories
          }
        }
      }
    }
    documentCategories: allMdx(
      filter: { fields: { contentType: { eq: "documentCategories" } } }
      sort: { fields: frontmatter___order, order: ASC }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
          }
        }
      }
    }
    documents: allMdx(
      filter: { fields: { contentType: { eq: "documents" } } }
      sort: { fields: frontmatter___publishedDate, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            file
            documentCategories
          }
        }
      }
    }
  }
`
